import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AppCommentModal from "../../components/appcommentmodal";
import { useSelector, useDispatch } from "react-redux";
import ActionHelper from "../../store/actions/action-helper";
import CAMPAIGN_ACTION_TYPES from "../../store/actions/CAMPAIGN_ACTION_TYPES";
import RESULT_ACTION_TYPES from "../../store/actions/RESULT_ACTION_TYPES";
import { STATE } from "../../config/constants";
import { getCampaignTokenInfo, resetStatusKey } from "../../utils/utils";
import "./style.scss";

export function SortableItem({ id, index, item, isFrom, setItems, isOn }) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.2 : 1,
  };

  const clickRatingIcon = (icon) => {
    setItems((items) => {
      items[index].satisfaction = icon;
      return [...items];
    });
  };

  return (
    <Row className="mb-2">
      {isFrom === "list" && (
        <div className="index">
          {index + 1 < 10 ? "0" + JSON.stringify(index + 1) : index + 1}
        </div>
      )}
      <div
        className="priorities-blocks"
        ref={setNodeRef}
        style={style}
        {...attributes}
      >
        <Col xs={24} md={24 - index} lg={24 - index}>
          {isDragging ? (
            <div className="priorities-inside" />
          ) : (
            <div className="priorities-inside" {...listeners}>
              <div className="left">
                <div className="move-icon">
                  <i className="icon-move-icon"></i>
                </div>
                <div className="info">
                  <i className={item.iconName}></i>
                  <h6>{item.label}</h6>
                </div>
              </div>
              {!isOn && (              
                <div className="emoji">
                  <ul>
                    <li>
                      <Link
                        to="#"
                        data-testid="satisfaction-icon-very-sad"
                        onClick={() => clickRatingIcon(1)}
                        className={`d-flex text-decoration-none ${
                          item.satisfaction === 1 && "very-sad"
                        }`}
                      >
                        <i className="icon-very-sad-icon"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-testid="satisfaction-icon-sad"
                        onClick={() => clickRatingIcon(2)}
                        className={`d-flex text-decoration-none ${
                          item.satisfaction === 2 && "sad"
                        }`}
                      >
                        <i className="icon-sad-icon"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-testid="satisfaction-icon-happy"
                        onClick={() => clickRatingIcon(3)}
                        className={`d-flex text-decoration-none ${
                          item.satisfaction === 3 && "happy"
                        }`}
                      >
                        <i className="icon-happy-icon"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-testid="satisfaction-icon-very-happy"
                        onClick={() => clickRatingIcon(4)}
                        className={`d-flex text-decoration-none ${
                          item.satisfaction === 4 && "very-happy"
                        }`}
                      >
                        <i className="icon-very-happy-icon"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </Col>
      </div>
    </Row>
  );
}

const Prioritizing = () => {
  const { t, i18n } = useTranslation();
  const {
    token: { resultstate, data, campaignSurveyDataResponse },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    const { type } = getCampaignTokenInfo();
    setIsOn(type === "on");
    setItems(campaignSurveyDataResponse?.motivators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resultstate === STATE.SUCCESS) {
      setLoading(false);
      resetStatusKey(dispatch, resultstate, RESULT_ACTION_TYPES.SET_STATE);
      if (campaignSurveyDataResponse.showFeedback) {
        navigate("/result");
      } else {
        navigate("/resultNoFeedback");
      }
    } else if (resultstate === STATE.FAILURE) {
      setLoading(false);
      resetStatusKey(dispatch, resultstate, RESULT_ACTION_TYPES.SET_STATE);
    }
    // eslint-disable-next-line
  }, [resultstate]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (value) => {
    setCommentText(value);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart({ active }) {
    setActiveId(active.id);
    let data = items.find((obj) => obj.id === active.id);
    let index = items.findIndex((obj) => obj.id === active.id);
    setActiveItem(data);
    setActiveIndex(index);
  }

  function handleDragOver({ active, over }) {
    setItems((items) => {
      const oldIndex = items.findIndex((obj) => obj.id === active.id);
      const newIndex = items.findIndex((obj) => obj.id === over.id);
      return arrayMove(items, oldIndex, newIndex);
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    setActiveId(null);
    setActiveItem(null);
    setActiveIndex(null);
    if (active.id !== over.id) {
      setItems((prev) => {
        const oldIndex = prev.findIndex((item) => item.id === active.id);
        const newIndex = prev.findIndex((item) => item.id === over.id);
        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  }

  const submitData = () => {
    let finalMotivators = items.map((obj, index) => {
      return {
        ...obj,
        priority: index + 1,
      };
    });

    let obj = {
      campaignSurveyDataResponse: {
        ...campaignSurveyDataResponse,
        motivators: finalMotivators,
      },
    };

    let resultData = {
      campaignId: data.campaign_id,
      tokenId: data.token,
      data: {
        companyName: data.companyName,
        // language: data.language ? data.language === "esLa" ? "es" : data.language : "es",
        language: i18n.language,
        isTest: data?.isTest ?? false,
        iteration: data.iteration || "default.iteration",
        answers: {
          motivators: finalMotivators,
          demographics: campaignSurveyDataResponse.demographics,
          comment: commentText,
        },
      },
    };
    setLoading(true);
    dispatch(ActionHelper.fire(CAMPAIGN_ACTION_TYPES.SET_STATE, obj));
    dispatch(ActionHelper.fire(RESULT_ACTION_TYPES.REQUEST, resultData));
  };

  return (
    <Spin size="large" spinning={loading}>
      <div aria-label="Prioritizing" className="container-fluid p-0">
        <div className="priorities">
          <Row gutter={[24, 16]}>
            <Col
              xs={24}
              sm={20}
              md={20}
              lg={8}
              className="d-flex align-items-center"
            >
              <div className="title-section">
                <h1>{t("prioritizingLabel")}</h1>
                <p className="p-0 m-0">{t("prioritizingText")}</p>
                <div className="d-none d-lg-block">
                  <Link
                    data-testid="comment-button"
                    to="#"
                    className="btn-clarify"
                    onClick={showModal}
                  >
                    <i className="icon-comment-icon me-1" />
                    {commentText ? t("editCommentButton") : t("commentButton")}
                  </Link>
                  <div className="mt-3">
                    {commentText && <p>{commentText}</p>}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={16}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
              >
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  {items.map((item, index) => (
                    <SortableItem
                      isFrom="list"
                      key={item.id}
                      id={item.id}
                      index={index}
                      item={item}
                      setItems={setItems}
                      isOn={isOn}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  {activeId ? (
                    <SortableItem
                      isFrom="drag"
                      id={activeId}
                      index={activeIndex}
                      item={activeItem}
                      setItems={setItems}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
              <Row className="d-block d-lg-none">
                <Col xs={24} md={24}>
                  <div className="btn-clarify">
                    <i className="icon-comment-icon me-1"></i>
                    <Link to="#" onClick={showModal}>
                      {commentText
                        ? t("editCommentButton")
                        : t("commentButtonMobile")}
                    </Link>
                  </div>
                  <div className="mt-3">
                    {commentText && <p>{commentText}</p>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Link
            data-testid="finish-button"
            to="#"
            onClick={submitData}
            className="btn-finish disabled-btn mt-5"
          >
            {t("finishButton")}
            <i className="icon-arrow-icon" />
          </Link>
        </div>
        <AppCommentModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </Spin>
  );
};

Prioritizing.propTypes = {};

export default Prioritizing;
