/**
 * Helper para la creación de actions.
 * Ayuda a facilitar la creación de mensajes {type: n, payload: k}
 */
class ActionHelper {
  /**
   *
   * @param type Identificador de la acción
   * @param payload Parametros de la acción
   * @returns {{type: *, payload: *}}
   */
  fire(type, payload = null) {
    return {
      type,
      payload,
    };
  }

  /**
   * Send data to redux without saga
   * @param type Identificador de la acción
   * @param payload Parametros de la acción
   * @returns {{type: *, payload: *}}
   */
  set(type, payload = null) {
    return {
      type,
      payload,
    };
  }
}

const actionHelper = new ActionHelper()

export default actionHelper;
