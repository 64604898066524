import React, { useState } from "react";
import { Row, Col, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import RESULT_ACTION_TYPES from "../../store/actions/RESULT_ACTION_TYPES";
import AppProgress from "../../components/appprogress";
import AppResultQuestion from "../../components/appresultquestion";
import domToPdf from "dom-to-pdf";
import ReactMarkdown from "react-markdown";
import AppResultWarningModal from "../../components/appresultwarningmodal";
import "./style.scss";

const { Panel } = Collapse;

const Result = () => {
  let printContent;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    token: { data, resultData: finalData },
  } = useSelector((state) => state);
  const [resultData, setData] = useState(finalData);
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const update = (value) => {
    setData(value);
  };

  const updateResult = (data, callBack) => {
    return {
      type: RESULT_ACTION_TYPES.RESULT_UPDATE_REQUEST,
      data,
      callBack,
    };
  };

  const handleSubmit = (value) => {
    let obj = {
      ...resultData,
      info: {
        ...resultData.info,
        matched: value,
      },
    };
    let tempdata = {
      campaignId: data.campaign_id,
      tokenId: data.token,
      data: {
        info: {
          ...resultData.info,
          matched: value,
        },
      },
    };
    dispatch(
      updateResult(tempdata, (value) => {
        if (value) {
          update(obj);
        }
      })
    );
  };

  const handleCollapseSubmit = (value, item) => {
    let tempObj = {
      ...resultData,
      items: resultData.items.map((obj) =>
        obj.label === item.label
          ? {
              ...obj,
              info: {
                ...obj.info,
                matched: value,
              },
            }
          : {
              ...obj,
              info: {
                ...obj.info,
              },
            }
      ),
    };
    let tempdata = {
      campaignId: data.campaign_id,
      tokenId: data.token,
      data: {
        items: resultData.items.map((obj) =>
          obj.label === item.label
            ? {
                ...obj,
                info: {
                  ...obj.info,
                  matched: value,
                },
              }
            : {
                ...obj,
                info: {
                  ...obj.info,
                },
              }
        ),
      },
    };
    dispatch(
      updateResult(tempdata, (value) => {
        if (value) {
          update(tempObj);
        }
      })
    );
  };

  const downloadPdfDocument = (e) => {
    e.preventDefault();
    const options = {
      filename: "Dathum.pdf",
      overrideWidth: 1100,
      compression: "FAST",
    };
    const printContentnew = document.createElement("download");
    printContentnew.classList.add("download");
    printContentnew.innerHTML = printContent.outerHTML;
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      const parentSvg = printContentnew.querySelector(".ant-progress-inner");
      const SVG = parentSvg.querySelector("svg");
      const XML = new XMLSerializer().serializeToString(SVG);
      const SVG64 = btoa(XML);
      const img = new Image();
      img.height = 170;
      img.width = 170;
      img.src = "data:image/svg+xml;base64," + SVG64;
      parentSvg.replaceChild(img, SVG);
    }
    return domToPdf(printContentnew, options, () => {
      printContentnew.innerHTML = "";
    });
  };

  const renderTitle = (value) => {
    switch (value) {
      case "team":
        return t("team");
      case "work":
        return t("work");
      case "management":
        return t("management");
      case "conditions":
        return t("conditions");
      default:
        return value;
    }
  };

  return (
    <div aria-label="Result" className="container-fluid p-0">
      <div
        className="result-main"
        ref={(el) => {
          printContent = el;
        }}
      >
        <Row>
          <div className="header">
            <Col xs={24} md={12} lg={12}>
              <div className="top-heading-strip">
                <h1>{t("result")}</h1>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12} className="d-none d-md-block">
              <div className="right-links">
                <Link
                  aria-label={"downloadButton"}
                  to="#"
                  onClick={downloadPdfDocument}
                >
                  <i className="icon-pdf-icon" /> {t("download")}
                </Link>
                <Link
                  aria-label={"printButton"}
                  onClick={() => {
                    try {
                      // Print for Safari browser
                      document.execCommand("print", false, null);
                    } catch {
                      window.print();
                    }
                  }}
                  to="#"
                >
                  <i className="icon-print-icon"></i> {t("print")}
                </Link>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24} xl={7}>
            <Row className="align-items-center">
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={24}
                className="text-center text-md-start print-col-30"
              >
                <AppProgress type="circle" percent={resultData.result} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={24}
                className="print-col-70"
              >
                <div className="title-bar">
                  <h2>{resultData.info.title}</h2>
                  <ReactMarkdown>{resultData.info.info}</ReactMarkdown>
                  <ReactMarkdown>{resultData.info.description}</ReactMarkdown>
                  <AppResultQuestion
                    title={t("question")}
                    active={resultData.info.matched}
                    handleYes={() => handleSubmit(true)}
                    handleNo={() => handleSubmit(false)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={17}>
            <div className="accordion">
              <div className="accordion-title">
                <h2>{t("resultHeader")}</h2>
              </div>
              <Collapse
                className="accordion-print"
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={resultData.items.map((item, index) => index)}
                expandIcon={({ isActive }) => (
                  <i
                    className={isActive ? "icon-minus-icon" : "icon-plus-icon"}
                  />
                )}
              >
                {resultData.items.map((item, index) => (
                  <Panel
                    header={
                      <div className="d-flex align-items-center justify-content-between gap-4 up100">
                        <AppProgress
                          title={renderTitle(item.label)}
                          percent={item.result}
                          showInfo={false}
                        />
                      </div>
                    }
                    key={index}
                  >
                    {item?.info?.teamItems &&
                    item?.info?.teamItems?.length > 0 ? (
                      <>
                        {item.info.teamItems.map((dataItem, dataIndex) => (
                          <div key={dataIndex} className="content-box-info">
                            <h3>{dataItem.title}</h3>
                            <ReactMarkdown>{dataItem.key}</ReactMarkdown>
                            {dataItem.warning && (
                              <div className="callout callout-warning">
                                <div className="callout-title">
                                  <i className="icon-warn-icon"></i>
                                  <h4>{t("warnings")}</h4>
                                </div>
                                <ReactMarkdown>
                                  {dataItem.warning}
                                </ReactMarkdown>
                              </div>
                            )}
                            {dataItem.mission && (
                              <div className="teamitemcallout callout-info">
                                <div className="callout-title">
                                  <i className="icon-mission-icon"></i>
                                  <h4>{t("mission")}</h4>
                                </div>
                                <ReactMarkdown>
                                  {dataItem.mission}
                                </ReactMarkdown>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div key={index} className="content-box-info">
                        <h3>{item.info.title}</h3>
                        <ReactMarkdown>{item.info.key}</ReactMarkdown>
                        {item.info.warning && (
                          <div className="callout callout-warning">
                            <div className="callout-title">
                              <i className="icon-warn-icon"></i>
                              <h4>{t("warnings")}</h4>
                            </div>
                            <ReactMarkdown>{item.info.warning}</ReactMarkdown>
                          </div>
                        )}
                        {item.info.mission && (
                          <div className="callout callout-info">
                            <div className="callout-title">
                              <i className="icon-mission-icon"></i>
                              <h4>{t("mission")}</h4>
                            </div>
                            <ReactMarkdown>{item.info.mission}</ReactMarkdown>
                          </div>
                        )}
                      </div>
                    )}
                  </Panel>
                ))}
              </Collapse>
              <Collapse
                accordion
                className="accordion-default"
                expandIconPosition="end"
                bordered={false}
                // defaultActiveKey={0}
                expandIcon={({ isActive }) => (
                  <i
                    className={isActive ? "icon-minus-icon" : "icon-plus-icon"}
                  />
                )}
              >
                {resultData.items.map((item, index) => (
                  <Panel
                    header={
                      <div className="w-100 d-flex align-items-center justify-content-between gap-4 up100">
                        <AppProgress
                          title={renderTitle(item.label)}
                          percent={item.result}
                          showInfo={false}
                        />
                      </div>
                    }
                    key={index}
                  >
                    {item?.info?.teamItems &&
                    item?.info?.teamItems?.length > 0 ? (
                      <>
                        {item.info.teamItems.map((dataItem, dataIndex) => (
                          <div key={dataIndex} className="content-box-info">
                            <h3>{dataItem.title}</h3>
                            <ReactMarkdown>{dataItem.key}</ReactMarkdown>
                            {dataItem.warning && (
                              <div className="callout callout-warning">
                                <div className="callout-title">
                                  <i className="icon-warn-icon"></i>
                                  <h4>{t("warnings")}</h4>
                                </div>
                                <ReactMarkdown>
                                  {dataItem.warning}
                                </ReactMarkdown>
                              </div>
                            )}
                            {dataItem.mission && (
                              <div className="teamitemcallout callout-info">
                                <div className="callout-title">
                                  <i className="icon-mission-icon"></i>
                                  <h4>{t("mission")}</h4>
                                </div>
                                <ReactMarkdown>
                                  {dataItem.mission}
                                </ReactMarkdown>
                              </div>
                            )}
                          </div>
                        ))}
                        <div className="content-box-info">
                          <AppResultQuestion
                            title={t("question")}
                            active={item.info.matched}
                            handleYes={() => handleCollapseSubmit(true, item)}
                            handleNo={() => handleCollapseSubmit(false, item)}
                          />
                        </div>
                      </>
                    ) : (
                      <div key={index} className="content-box-info">
                        <h3>{item.info.title}</h3>
                        <ReactMarkdown>{item.info.key}</ReactMarkdown>
                        {item.info.warning && (
                          <div className="callout callout-warning">
                            <div className="callout-title">
                              <i className="icon-warn-icon"></i>
                              <h4>{t("warnings")}</h4>
                            </div>
                            <ReactMarkdown>{item.info.warning}</ReactMarkdown>
                          </div>
                        )}
                        {item.info.mission && (
                          <div className="callout callout-info">
                            <div className="callout-title">
                              <i className="icon-mission-icon"></i>
                              <h4>{t("mission")}</h4>
                            </div>
                            <ReactMarkdown>{item.info.mission}</ReactMarkdown>
                          </div>
                        )}
                        <AppResultQuestion
                          title={t("question")}
                          active={item.info.matched}
                          handleYes={() => handleCollapseSubmit(true, item)}
                          handleNo={() => handleCollapseSubmit(false, item)}
                        />
                      </div>
                    )}
                  </Panel>
                ))}
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={24}>
          <div className="footer">
            <div className="copyright">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="144.86"
                height="25.673"
                viewBox="0 0 144.86 25.673"
              >
                <path
                  d="M96.722,17.743V.334h4.191V17.743c0,1.091,0,2.739,5.153,2.739,5.19,0,5.19-1.648,5.19-2.739V4.533h-4.181V.334h4.191l0,4.2h4.191l-.012,13.212c0,4.6-3.157,6.927-9.382,6.927C99.866,24.673,96.722,22.341,96.722,17.743Zm25.025,6.6V3.509A2.786,2.786,0,0,1,124.625.446h.631A3.128,3.128,0,0,1,128,1.987l4.566,6.958c.081.121.163.248.238.362.072-.107.148-.223.225-.337l4.61-7.031A3.049,3.049,0,0,1,140.311.445h.631a2.78,2.78,0,0,1,2.918,3.063V24.338h-4.19V6.5l-3.192,4.812c-.241.36-.472.7-.723,1.014h.013L132.8,16.843l-2.962-4.516h.014c-.252-.311-.482-.651-.724-1.013L125.94,6.5V24.34Zm-66.146,0V4.637H47.979V.446H67.412v4.19H59.792v19.7Zm-16,0V6.927c0-1.09,0-2.736-5.153-2.736-5.19,0-5.19,1.646-5.19,2.736V20.137l4.181.009v4.191H29.243v-4.2h-4.19l.009-13.209C25.061,2.33,28.219,0,34.444,0c6.2,0,9.344,2.33,9.343,6.927V24.339Zm-39.6,0V.445H10.905c7.858,0,7.858,5.327,7.859,7.078v9.85c0,2.992-.814,6.966-7.859,6.966Zm4.191-4.193h6.714c3.666,0,3.666-1.1,3.666-2.773V7.522c0-1.612,0-2.885-3.665-2.885H4.191Zm82.053,4.192V14.487H75.789v9.851H71.6V.445h4.191V10.3H86.244V.445h4.19V24.338Z"
                  transform="translate(0.5 0.5)"
                  fill="#28282d"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </svg>
              <p>{t("info")}</p>
            </div>
          </div>
          <div className="d-block d-md-none">
            <div className="links-mobile">
              <Link
                aria-label={"downloadButton"}
                to="#"
                onClick={downloadPdfDocument}
              >
                <i className="icon-pdf-icon" /> {t("download")}
              </Link>
              <Link
                aria-label={"printButton"}
                onClick={() => {
                  try {
                    // Print for Safari browser
                    document.execCommand("print", false, null);
                  } catch {
                    window.print();
                  }
                }}
                to="#"
              >
                <i className="icon-print-icon"></i> {t("print")}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <AppResultWarningModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
};

Result.propTypes = {};

export default Result;
