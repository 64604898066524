import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AppButton = ({ title, className, onClick, to }) => {
  return (
    <Link
      aria-label={"AppButton"}
      to={to}
      onClick={onClick}
      className={className}
    >
      {title} <i className="icon-arrow-icon"></i>
    </Link>
  );
};

AppButton.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

AppButton.defaultProps = {
  className: "btn btn-secondary",
  to: "#",
};

export default AppButton;
