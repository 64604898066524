import React, { useState } from "react";
import { Row, Col } from "antd";
import AppDropdown from "../../components/appdropdown";
import AppButton from "../../components/appbutton";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ActionHelper from "../../store/actions/action-helper";
import CAMPAIGN_ACTION_TYPES from "../../store/actions/CAMPAIGN_ACTION_TYPES";
import "./style.scss";

const MultiDemographic = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    token: { campaignSurveyData },
  } = useSelector((state) => state);
  const [selectedData, setSelectedData] = useState([]);

  const onDropdownSelect = (value, item) => {
    const { id: questionId } = item;
    let index = selectedData.findIndex((obj)  => obj.question === questionId);
    const demographicData =  {
      question: questionId,
      values: [value.id],
    };
    if (index !== -1) {
      selectedData[index] = demographicData;
    } else {
      selectedData.push(demographicData);
    }
    setSelectedData([...selectedData]);
  };

  const submitData = () => {
    let data = {
      campaignSurveyDataResponse: {
        ...campaignSurveyData,
        demographics: selectedData,
      },
    };
    dispatch(ActionHelper.fire(CAMPAIGN_ACTION_TYPES.SET_STATE, data));
  };

  return (
    <div aria-label="MultiDemographic" className="container-fluid px-0">
      <div className="multidemographic">
        <div className="outer-padding">
          <div className="inside-padding">
            <div className="content-area">
              <Row>
                <Col>
                  <div className="title">
                    <h1>{t("demographicLabel")}</h1>
                    <p>{t("demographicText")}</p>
                  </div>
                </Col>
              </Row>
              <Row gutter={[30]}>
                {campaignSurveyData?.demographics?.map((item, index) => (
                  <Col key={index}>
                    <AppDropdown
                      headers={[item.question.find((element) => element.language === i18n.language).text]}
                      placeholder={t('selectOption')}
                      data={item.values.map((value) => {
                        return { key: value.id, label: value.value.find((element) => element.language === i18n.language).text };
                      })}
                      onSelect={(value) => onDropdownSelect(value, item)}
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col className="mt-3 mt-lg-5">
                  <AppButton
                    title={t("demographicButton")}
                    className={classNames("btn btn-primary", {
                      "disabled-btn":
                        selectedData.length ===
                        campaignSurveyData?.demographics?.length
                          ? false
                          : true,
                    })}
                    onClick={submitData}
                    to={
                      selectedData.length ===
                      campaignSurveyData?.demographics?.length
                        ? "/indicator"
                        : "#"
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MultiDemographic.propTypes = {};

export default MultiDemographic;
