import "./assets/css/theme.css";
import "./assets/sass/styles.scss";
import "./locales/i18n";

import React from "react";
import ReactDOM from "react-dom/client";
import { createMemoryRouter, RouterProvider } from 'react-router-dom';
import routes from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") || document.createElement("div")
);

const initialEntries = ['/', '/code'];
const initialIndex = window.location.pathname === initialEntries[1] ? 1 : 0;

root.render(
  <>
    <RouterProvider router={createMemoryRouter(routes, {
      initialEntries,
      initialIndex,
    })} />
  </>
);
