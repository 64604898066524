import { STATE } from "../../config/constants";
import TOKEN_ACTION_TYPES from "../actions/TOKEN_ACTION_TYPES";
import CAMPAIGN_ACTION_TYPES from "../actions/CAMPAIGN_ACTION_TYPES";
import RESULT_ACTION_TYPES from "../actions/RESULT_ACTION_TYPES";

const defaultState = {
  language: null,
  state: STATE.NONE,
  campaignstate: STATE.NONE,
  resultstate: STATE.NONE,
  data: {},
  campaignSurveyData: {},
  resultData: {},
  campaignSurveyDataResponse: {},
  error: null,
  errorcampaign: null,
  errorresult: null,
};

const ACTION_HANDLERS = {
  [TOKEN_ACTION_TYPES.SET_LANGUAGE_STATE]: (state, action) => ({
    language: action.payload,
    state: STATE.NONE,
    campaignstate: STATE.NONE,
    resultstate: STATE.NONE,
    data: {},
    campaignSurveyData: {},
    resultData: {},
    campaignSurveyDataResponse: {},
    error: null,
    errorcampaign: null,
    errorresult: null,
  }),
  [TOKEN_ACTION_TYPES.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
  }),
  [TOKEN_ACTION_TYPES.REQUEST]: (state) => ({
    language: null,
    campaignstate: STATE.NONE,
    resultstate: STATE.NONE,
    data: {},
    campaignSurveyData: {},
    resultData: {},
    campaignSurveyDataResponse: {},
    error: null,
    errorcampaign: null,
    errorresult: null,
    state: STATE.FETCHING,
  }),
  [TOKEN_ACTION_TYPES.CODE_REQUEST]: (state) => ({
    language: null,
    campaignstate: STATE.NONE,
    resultstate: STATE.NONE,
    data: {},
    campaignSurveyData: {},
    resultData: {},
    campaignSurveyDataResponse: {},
    error: null,
    errorcampaign: null,
    errorresult: null,
    state: STATE.FETCHING,
  }),
  [TOKEN_ACTION_TYPES.SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    state: STATE.SUCCESS,
    error: null,
  }),
  [TOKEN_ACTION_TYPES.FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
  [CAMPAIGN_ACTION_TYPES.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    campaignstate: STATE.NONE,
    campaignSurveyData: {},
  }),
  [CAMPAIGN_ACTION_TYPES.REQUEST]: (state) => ({
    ...state,
    campaignstate: STATE.FETCHING,
  }),
  [CAMPAIGN_ACTION_TYPES.SUCCESS]: (state, action) => ({
    ...state,
    campaignSurveyData: action.payload,
    campaignstate: STATE.SUCCESS,
    errorcampaign: null,
  }),
  [CAMPAIGN_ACTION_TYPES.FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      campaignstate: STATE.FAILURE,
      errorcampaign: error,
    };
  },
  [RESULT_ACTION_TYPES.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    resultstate: STATE.NONE,
  }),
  [RESULT_ACTION_TYPES.REQUEST]: (state) => ({
    ...state,
    resultstate: STATE.FETCHING,
  }),
  [RESULT_ACTION_TYPES.SUCCESS]: (state, action) => ({
    ...state,
    resultData: action.payload,
    resultstate: STATE.SUCCESS,
    errorresult: null,
  }),
  [RESULT_ACTION_TYPES.FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      resultstate: STATE.FAILURE,
      errorresult: error,
    };
  },
  [RESULT_ACTION_TYPES.RESULT_UPDATE_REQUEST]: (state) => ({
    ...state,
  }),
};

const reducer = (state = defaultState, action = STATE.NONE) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
