import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Slider as AntdSilder, Rate } from "antd";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { customIcons, iconNameIndicator } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import ActionHelper from "../../store/actions/action-helper";
import CAMPAIGN_ACTION_TYPES from "../../store/actions/CAMPAIGN_ACTION_TYPES";
import _ from "lodash";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { getCampaignTokenInfo } from "../../utils/utils";

let timeout;
const Indicator = () => {
  const ref = useRef();
  const {
    token: { campaignSurveyDataResponse },
  } = useSelector((state) => state);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [limitIndex, setLimitIndex] = useState(0);
  const [isAcademy, setIsAcademy] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    const { academy, type } = getCampaignTokenInfo();

    setIsAcademy(academy!==null);
    setIsOn(type==="on");
    setIsOff(type==="off");
    setData(campaignSurveyDataResponse?.motivators?.[i18n.language]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = (value) => {
    setData([...value]);
  };

  const navigateTo = () => {
    let sortData = data
      .sort(
        function (a, b) {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1;
          return this.indexOf(b) - this.indexOf(a);
        }.bind(data)
      )
      .reverse();
    let obj = {
      campaignSurveyDataResponse: {
        ...campaignSurveyDataResponse,
        motivators: sortData,
      },
    };
    dispatch(ActionHelper.fire(CAMPAIGN_ACTION_TYPES.SET_STATE, obj));
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      navigate("/prioritizing");
    }, 1000);
  };

  const navigateToNext = (delay = 800) => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      ref.current.slickNext();
    }, delay);
  };

  const navigateToPrev = () => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      ref.current.slickPrev();
    }, 300);
  };

  const renderClassName = () => {
    return "all-squares";
  };

  const evalLimitIndex = (index) => {
    if (limitIndex <= index) {
      setLimitIndex(index + 1);
    }
  }

  const updateRating = (index, item, satisfaction) => {
    data[index].satisfaction = satisfaction;
    update(data);
    if (item.priority > 0) {
      evalLimitIndex(index);
      if (data.length === currentIndex + 1) {
        navigateTo();
      } else {
        navigateToNext();
      }
    }
  };

  const messages =[
    t("offMessageAutonomy"),
    t("offMessageInfluence"),
    t("offMessageChallenges"),
    t("offMessageLearning"),
    t("offMessageRecognition"),
    t("offMessageFriendship"),
    t("offMessagePurpose"),
    t("offMessageSalary"),
    t("offMessageTime"),
    t("offMessagePosition"),
    t("offMessageStability"),
    t("offMessageResources")
  ];

  const questions = [
    t("offQuestionAutonomy"),
    t("offQuestionInfluence"),
    t("offQuestionChallenges"),
    t("offQuestionLearning"),
    t("offQuestionRecognition"),
    t("offQuestionFriendship"),
    t("offQuestionPurpose"),
    t("offQuestionSalary"),
    t("offQuestionTime"),
    t("offQuestionPosition"),
    t("offQuestionStability"),
    t("offQuestionResources")
  ];

  return (
    <div aria-label="Indicator" className="indicator">
      <div className="icons-bar">
        <ul className="list-group flex-row flex-lg-column">
          {_.merge(data, iconNameIndicator()).map((item, index) => (
            <li
              key={index}
              data-testid="indicator-bar"
              className={`list-group-item ${
                currentIndex === index ? "selected" : ""
              } ${item.satisfaction > 0 ? "active" : ""}`}
            >
              <Link
                to="#"
                onClick={() => {
                  if (item.satisfaction > 0) {
                    ref.current.slickGoTo(index);
                  }
                }}
              >
                <i className={item.iconName} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-fluid p-0">
        <Row>
          <div className="section-padding">
            <Slider
              afterChange={(index) => setCurrentIndex(index)}
              dots={false}
              ref={ref}
              nextArrow={<></>}
              prevArrow={<></>}
              swipe={false}
              verticalSwiping={false}
              vertical={true}
              slidesToShow={1}
              slidesToScroll={1}
              infinite={false}
            >
              {_.merge(data, iconNameIndicator()).map((item, index) => (
                <div key={index} className="view">
                  <div className="main-info">
                    <Col xs={24} lg={12}>
                      <div className="title-bar">
                        <div className="top-icon">
                          <i className={item.iconName}></i>
                        </div>
                        <h1>{item.label}</h1>
                        <p>{isOff ? messages[index] : item.message}</p>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div className="slider-progress-bar">
                        <div className="slider-progress">
                          <label>{item.question1}</label>
                          <div className="indicator-slider">
                            <AntdSilder
                              className={renderClassName()}
                              marks={Array.from(Array(12).keys())}
                              defaultValue={item.priority}
                              value={item.priority}
                              min={0}
                              max={12}
                              onAfterChange={(value) => {
                                if (value > 0) {
                                  data[index].priority = value;
                                  update(data);
                                  if (item.satisfaction > 0) {
                                    evalLimitIndex(index);
                                    if (data.length === currentIndex + 1) {
                                      navigateTo();
                                    } else {
                                      navigateToNext();
                                    }
                                  } else if (isAcademy) {
                                    navigateToNext();
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="indicator-rate">
                            <Rate
                              count={12}
                              className={renderClassName()}
                              value={item.priority}
                              defaultValue={item.priority}
                              onChange={(value) => {
                                if (value > 0) {
                                  if(isOn){
                                    updateRating(index, item, 2.5);
                                    navigateToNext();
                                  }
                                  data[index].priority = value;
                                  update(data);
                                  if (item.satisfaction > 0) {
                                    evalLimitIndex(index);
                                    if (data.length === currentIndex + 1) {
                                      navigateTo();
                                    } else {
                                      navigateToNext();
                                    }
                                  }
                                }
                              }}
                              character={({ index }) => customIcons[index + 1]}
                            />
                          </div>
                          {!isOn ? <div className="rating">
                            <label>{isOff ? questions[index] : item.question2}</label>
                            <ul className="d-flex justify-content-start p-0 gap-3 m-0">
                              <li className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  data-testid="rating-icon-very-sad"
                                  onClick={() => updateRating(index, item, 1)}
                                  className={`d-flex text-decoration-none ${
                                    item.satisfaction === 1 && "very-sad"
                                  }`}
                                >
                                  <i className="icon-very-sad-stroke-icon"></i>
                                </Link>
                              </li>
                              <li className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  data-testid="rating-icon-sad"
                                  onClick={() => updateRating(index, item, 2)}
                                  className={`d-flex text-decoration-none ${
                                    item.satisfaction === 2 && "sad"
                                  }`}
                                >
                                  <i className="icon-sad-stroke-icon"></i>
                                </Link>
                              </li>
                              <li className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  data-testid="rating-icon-happy"
                                  onClick={() => updateRating(index, item, 3)}
                                  className={`d-flex text-decoration-none ${
                                    item.satisfaction === 3 && "happy"
                                  }`}
                                >
                                  <i className="icon-happy-stroke-icon"></i>
                                </Link>
                              </li>
                              <li className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  data-testid="rating-icon-very-happy"
                                  onClick={() => updateRating(index, item, 4)}
                                  className={`d-flex text-decoration-none ${
                                    item.satisfaction === 4 && "very-happy"
                                  }`}
                                >
                                  <i className="icon-very-happy-stroke-icon"></i>
                                </Link>
                              </li>
                            </ul>
                          </div> : null}
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Row>
      </div>
      <div className="arrow-bar">
        <ul className="d-flex flex-row flex-lg-column justify-content-center p-0 m-0 gap-5">
          <li className="d-flex align-items-center">
            <Link
              onClick={() => (currentIndex > 0 ? navigateToPrev() : {})}
              to="#"
              data-testid="up-arrow"
              className={`d-flex text-decoration-none ${
                currentIndex > 0 ? "active" : ""
              }`}
            >
              <i className="icon-arrow-up-icon"></i>
            </Link>
          </li>
          <li className="d-flex align-items-center">
            <Link
              onClick={() => (limitIndex > currentIndex ? navigateToNext(100) : {})}
              data-testid="down-arrow"
              to="#"
              className={`d-flex text-decoration-none ${
                limitIndex > currentIndex ? "active" : ""
              }`}
            >
              <i className="icon-arrow-down-icon"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

Indicator.propTypes = {};

export default Indicator;
