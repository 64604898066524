import createSagaMiddleware from "redux-saga";
import { configureStore as createStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import reducer from "./reducers";
import rootSaga from "./sagas";

const persistConfig = {
  key: "root",
  storage,
};

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = createStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, logger],
  });
  sagaMiddleware.run(rootSaga);
  let persistor = persistStore(store);
  return { persistor, store };
}
