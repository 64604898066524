import { STATE } from "../config/constants";
import ActionHelper from "../store/actions/action-helper";

export const isNullOrUndefined = (value) =>
  value === null || value === undefined || value === '';

export const toQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");

export const customIcons = () => {
  let obj = {
    1: <></>,
    2: <></>,
    3: <></>,
    4: <></>,
    5: <></>,
    6: <></>,
    7: <></>,
    8: <></>,
    9: <></>,
    10: <></>,
    11: <></>,
    12: <></>,
  };
  return obj;
};

  export const extractType = (text) => {
    if (text.includes('--')) {
      return text.split('--')[0];
    } else {
      return text;
    }
  }

export const getCampaignTokenInfo = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const campaign = queryParams.get("campaign");
  const academy = queryParams.get("academy");
  const company = queryParams.get("company");

  const type = academy ? extractType(academy) : "campaign";

  console.log("getCampaignTokenInfo", campaign, token, academy, type, company);

  return { campaign, token, academy, type, company };
};

export const iconNameIndicator = () => {
  return [
    { iconName: "icon-autonomy-icon" },
    { iconName: "icon-influence-icon" },
    { iconName: "icon-challenge-icon" },
    { iconName: "icon-learning-icon" },
    { iconName: "icon-appreciation-icon" },
    { iconName: "icon-team-icon" },
    { iconName: "icon-values-icon" },
    { iconName: "icon-money-icon" },
    { iconName: "icon-time-icon" },
    { iconName: "icon-organization-icon" },
    { iconName: "icon-peace-icon" },
    { iconName: "icon-company-icon" },
  ];
};

/**
 * Reset the value of the key in the search state
 * @param dispatch
 * @param key
 * @param type
 */
export const resetStatusKey = (dispatch, key, type) => {
  const state = {
    [key]: STATE.NONE,
  };
  dispatch(ActionHelper.set(type, state));
};
