import React, { useState } from "react";
import { Menu, Dropdown, Space } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./style.scss";

const AppDropdown = ({ headers, placeholder, data, onSelect, customClass }) => {
  const [item, setItem] = useState("");

  function handleMenuClick({ key }) {
    let finalData = data.find((obj) => obj.key === key);
    if (finalData) {
      setItem(finalData.label);
      let finalObj = {};
      finalObj.values = [finalData.label];
      if (finalData?.key) {
        finalObj.id = finalData.key;
      }
      onSelect(finalObj);
    }
  }

  const menu = (
    <Menu data-testid="dropdown-menu" onClick={handleMenuClick} items={data} />
  );

  return (
    <div aria-label="AppDropdown" className={customClass ?? "select-category"}>
      {headers.map((header) => (
        <h2>{header}</h2>
      ))}
      <Dropdown overlay={menu}>
        <Link
          to="#"
          data-testid="button"
          className="btn btn-dropdown"
        >
          <Space aria-label={item} className="d-flex justify-content-between">
            {item ? item : placeholder}
            <i className="icon-show-icon"></i>
          </Space>
        </Link>
      </Dropdown>
    </div>
  );
};

const Option = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
});

AppDropdown.propTypes = {
  headers: PropTypes.array,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(Option).isRequired,
  onSelect: PropTypes.func,
  customClass: PropTypes.string,
};

export default AppDropdown;
