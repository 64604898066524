import React, { useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";

const AppCommentModal = ({ isModalVisible, handleOk, handleCancel }) => {
  const [commentText, setCommentText] = useState("");
  const { t } = useTranslation();

  return (
    <Modal
      className="commentary-model"
      maskClosable={false}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <h2 className="d-inline-flex align-items-center gap-1">
        <i className="icon-comment-stroke-icon d-none d-lg-block" />
        {t("commentLabel")}
      </h2>
      <p>{t("commentText")}</p>
      <form>
        <textarea
          aria-label={"input-textarea"}
          onChange={(e) => setCommentText(e.target.value)}
          className="form-control"
          rows={12}
          cols={6}
        />
        <div className="d-flex justify-content-between justify-content-sm-start gap-3 mt-3">
          <Link
            aria-label={"Cancel"}
            to="#"
            onClick={handleCancel}
            className="btn btn-light"
          >
            <i className="icon-cancel-icon" />
            {t("Cancel")}
          </Link>
          <Link
            to="#"
            aria-label={"Save"}
            onClick={() => handleOk(commentText)}
            className="btn btn-primary"
          >
            <i className="icon-save-icon" />
            {t("Save")}
          </Link>
        </div>
      </form>
    </Modal>
  );
};

AppCommentModal.propTypes = {
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default AppCommentModal;
