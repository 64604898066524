const TOKEN_ACTION_TYPES = {
  SET_LANGUAGE_STATE: "SET_LANGUAGE_STATE",
  SET_STATE: 'TOKEN_SET_STATE',
  REQUEST: 'TOKEN_REQUEST',
  CODE_REQUEST: 'TOKEN_CODE_REQUEST',
  SUCCESS: 'TOKEN_SUCCESS',
  FAILURE: 'TOKEN_FAILURE',
};

export default TOKEN_ACTION_TYPES;
