import React, { useState } from "react";
import { Row, Col } from "antd";
import AppDropdown from "../../components/appdropdown";
import AppButton from "../../components/appbutton";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ActionHelper from "../../store/actions/action-helper";
import CAMPAIGN_ACTION_TYPES from "../../store/actions/CAMPAIGN_ACTION_TYPES";
import "./style.scss";

const Demographic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    token: { campaignSurveyData },
  } = useSelector((state) => state);
  const [item, setItem] = useState(undefined);

  const submitData = () => {
    let data = {
      campaignSurveyDataResponse: {
        ...campaignSurveyData,
        demographics: item,
      },
    };
    dispatch(ActionHelper.fire(CAMPAIGN_ACTION_TYPES.SET_STATE, data));
  };

  return (
    <div aria-label="Demographic" className="container-fluid px-0">
      <div className="demographic">
        <div className="outer-padding">
          <div className="inside-padding">
            <div className="content-area">
              <Row>
                <Col>
                  <div className="title">
                    <h1>{t("demographicLabel")}</h1>
                    <p>{t("demographicText")}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {campaignSurveyData?.demographics?.length > 0 && (
                    <AppDropdown
                      header={campaignSurveyData?.demographics[0]?.question}
                      placeholder={"Selecciona una opción"}
                      data={campaignSurveyData?.demographics[0]?.values.map(
                        (item) => {
                          return { key: item, label: item };
                        }
                      )}
                      onSelect={(value) => setItem(value)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <AppButton
                    title={t("demographicButton")}
                    onClick={submitData}
                    className={classNames("btn btn-primary", {
                      "disabled-btn": !item,
                    })}
                    to={item ? "/indicator" : "#"}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Demographic.propTypes = {};

export default Demographic;
