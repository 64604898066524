const BASE = {
  baseUrl: process.env.REACT_APP_BASE_URL || '/',
  accountGa: process.env.REACT_APP_ACCOUNT_GA || '',
  enableGa: process.env.REACT_APP_ENABLE_GA || '',
  endpoints: {
    surveys: '/surveys'
  },
};

export const getVars = () => BASE;
