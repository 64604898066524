import React from "react";
import { Row, Col } from "antd";
import AppButton from "../../components/appbutton";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { isNullOrUndefined } from "../../utils/utils";

const Error400 = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const checkLocationObject = () => {
    if (!isNullOrUndefined(location?.state?.error)) {
      return (<h1>{t(location.state.error.data.info)}</h1>)
    }
    if (!isNullOrUndefined(location?.state?.errorcampaign)) {
      return (<h1>{t(location.state.errorcampaign.data.info)}</h1>)
    }
    return (<h1>{t("error400")}</h1>);
  }

  return (
    <div aria-label="Error400" className="home error" data-testid="Error400">
      <div className="container-fluid px-0">
        <div className="content-area">
          <Row>
            <Col xs={24} sm={20} md={21} lg={16}>
              <div className="title">
                {checkLocationObject()}
              </div>
              <AppButton title={t("return")} to="/" />
            </Col>
          </Row>
        </div>
      </div>
      <div className="red-stripe"></div>
      <div className="brand-logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="144.86"
          height="25.673"
          viewBox="0 0 144.86 25.673"
        >
          <path
            d="M96.722,17.743V.334h4.191V17.743c0,1.091,0,2.739,5.153,2.739,5.19,0,5.19-1.648,5.19-2.739V4.533h-4.181V.334h4.191l0,4.2h4.191l-.012,13.212c0,4.6-3.157,6.927-9.382,6.927C99.866,24.673,96.722,22.341,96.722,17.743Zm25.025,6.6V3.509A2.786,2.786,0,0,1,124.625.446h.631A3.128,3.128,0,0,1,128,1.987l4.566,6.958c.081.121.163.248.238.362.072-.107.148-.223.225-.337l4.61-7.031A3.049,3.049,0,0,1,140.311.445h.631a2.78,2.78,0,0,1,2.918,3.063V24.338h-4.19V6.5l-3.192,4.812c-.241.36-.472.7-.723,1.014h.013L132.8,16.843l-2.962-4.516h.014c-.252-.311-.482-.651-.724-1.013L125.94,6.5V24.34Zm-66.146,0V4.637H47.979V.446H67.412v4.19H59.792v19.7Zm-16,0V6.927c0-1.09,0-2.736-5.153-2.736-5.19,0-5.19,1.646-5.19,2.736V20.137l4.181.009v4.191H29.243v-4.2h-4.19l.009-13.209C25.061,2.33,28.219,0,34.444,0c6.2,0,9.344,2.33,9.343,6.927V24.339Zm-39.6,0V.445H10.905c7.858,0,7.858,5.327,7.859,7.078v9.85c0,2.992-.814,6.966-7.859,6.966Zm4.191-4.193h6.714c3.666,0,3.666-1.1,3.666-2.773V7.522c0-1.612,0-2.885-3.665-2.885H4.191Zm82.053,4.192V14.487H75.789v9.851H71.6V.445h4.191V10.3H86.244V.445h4.19V24.338Z"
            transform="translate(0.5 0.5)"
            fill="#28282d"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </svg>
      </div>
      <div className="icon-watermark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="373.043"
          height="484.616"
          viewBox="0 0 373.043 484.616"
        >
          <path
            d="M1195.509,312.576c-123.96,0-186.822,46.394-186.822,137.932l-.192,263.032h83.448v83.616h83.448V713.708l-83.232-.168V450.508c0-21.7,0-54.484,103.338-54.484,102.593,0,102.593,32.782,102.593,54.484V797.192h83.448V450.508C1381.55,358.97,1318.916,312.576,1195.509,312.576Z"
            transform="translate(-1008.495 -312.576)"
            fill="#f7f7f7"
          />
        </svg>
      </div>
    </div>
  );
};

Error400.propTypes = {};

export default Error400;
