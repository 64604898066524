import Cookies from 'universal-cookie';

import { COOKIE_NAME, HEADER_AUTH } from '../config/constants';

const debug = require('debug')('config:axios');

/**
 * Abstraction to redirect user
 * @param {String} path to redirect
 */
export const redirectTo = (path) => {
  document.location.href = path;
};

export const handleRequestSuccess = (request) => {
  const cookies = new Cookies();
  const requestConfig = {
    ...request,
  };

  const userToken = Object.keys(cookies.getAll()).find((cc) =>
    cc.endsWith(COOKIE_NAME)
  );
  if (userToken) {
    requestConfig.headers[HEADER_AUTH] = `${cookies.get(userToken).token}`;
  } else {
    debug('not session!');
  }
  return Promise.resolve(requestConfig);
};

export const handleRequestError = (err) => Promise.reject(err);

export const handleResponseSuccess = (response) => Promise.resolve(response);

export const handleResponseError = (err) => {
  const SIMPLE_ERROR_MESSAGE = '::: SERVER ERROR ::::';
  const SIGNOUT = '/signout';

  if (err.response) {
    debug();
  } else {
    switch (err.response.status) {
      case 400:
        debug('::: BAD REQUEST ::::');
        break;
      case 401:
        debug('::: UNATHORIZED ::::');
        console.log('No autorizado');
        redirectTo('/');
        break;
      case 403:
        debug('::: FORBIDDEN ::::');
        redirectTo(SIGNOUT);
        break;
      case 404:
        debug('::: NOT FOUND ::::');
        break;
      case 500:
        debug(SIMPLE_ERROR_MESSAGE);
        break;
      case 504:
        debug(SIMPLE_ERROR_MESSAGE);
        break;
      default:
        debug(SIMPLE_ERROR_MESSAGE);
        redirectTo('/500');
        break;
    }
    const { data, status } = err.response;
    return Promise.reject(
      new Error({
        data,
        status,
      })
    );
  }

  return Promise.reject(err);
};
