import { getVars } from "../../config/environment";
import ApiHelper from "../ApiHelper";

const getToken = ({ campaignId, tokenId }) => {
  const endpoint = getVars().endpoints.surveys;
  return ApiHelper.get(`${endpoint}/${campaignId}/tokens/${tokenId}`)
    .then((response) => ({
      response,
    }))
    .catch((error) => ({
      error,
    }));
};

const getTokenByCode = ({ campaignId, code }) => {
  const endpoint = getVars().endpoints.surveys;
  return ApiHelper.get(`${endpoint}/${campaignId}/code/${code}`)
    .then((response) => ({
      response,
    }))
    .catch((error) => ({
      error,
    }));
};

const getCampaignSurveyData = ({ company, campaignId, lang, isTest }) => {
  const endpoint = getVars().endpoints.surveys;
  const options = {
    params: {
      lang,
      isTest: isTest ?? undefined,
    }
  }
  return ApiHelper.get(`${endpoint}/${company}/campaigns/${campaignId}`, options)
    .then((response) => ({
      response,
    }))
    .catch((error) => ({
      error,
    }));
};

const postCampaignSurveyData = ({ campaignId, tokenId, data }) => {
  const endpoint = getVars().endpoints.surveys;
  return ApiHelper.post(`${endpoint}/${campaignId}/answer/${tokenId}`, data)
    .then((response) => ({
      response,
    }))
    .catch((error) => ({
      error,
    }));
};

const updateCampaignSurveyData = ({ campaignId, tokenId, data }) => {
  const endpoint = getVars().endpoints.surveys;
  return ApiHelper.patch(`${endpoint}/${campaignId}/answer/${tokenId}`, data)
    .then((response) => ({
      response,
    }))
    .catch((error) => ({
      error,
    }));
};

export {
  getToken,
  getTokenByCode,
  getCampaignSurveyData,
  postCampaignSurveyData,
  updateCampaignSurveyData,
};
