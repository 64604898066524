import React from "react";
import { Progress } from "antd";
import PropTypes from "prop-types";

const AppProgress = ({ title, percent, type, showInfo }) => {
  let color = "";
  if (percent > 74) {
    color = "#00cf00";
  } else if (percent > 59 && percent < 75) {
    color = "#a4d900";
  } else if (percent > 49 && percent < 60) {
    color = "#ffb536";
  } else {
    color = "#e82702";
  }

  return (
    <>
      {type !== "circle" && <h2 className="m-0 text-truncate">{title}</h2>}
      <Progress
        type={type}
        strokeColor={color}
        percent={percent}
        format={(percent) => (
          <>
            <h1>{percent}</h1>
            <h2>/100</h2>
          </>
        )}
        showInfo={showInfo}
      />
      {type !== "circle" && (
        <div className="d-flex align-items-center gap-1">
          <h3 style={{ color: color }} className="m-0">
            {percent}
          </h3>
          <h4 className="m-0">/100</h4>
        </div>
      )}
    </>
  );
};

AppProgress.propTypes = {
  percent: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default AppProgress;
