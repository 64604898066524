export const COOKIE_NAME = 'cookie';
export const HEADER_AUTH = 'auth_token';
export const DEV = 'development';
export const TEST = 'test';
export const TODAY = new Date();
export const FORMAT_DATE = 'DD/MM/yyyy';
export const STATE = Object.freeze({
  NONE: 'NONE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  MANAGED: 'MANAGED',
  NOTIFIED: 'NOTIFIED',
});
