import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import es from "./es.json";
import esLa from "./esLa.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import pt from "./pt.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: en,
      },
      es: {
        translations: es,
      },
      esLa: {
        translations: esLa,
      },
      de: {
        translations: de,
      },
      fr: {
        translations: fr,
      },
      it: {
        translations: it,
      },
      pt: {
        translations: pt,
      }
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
