/* eslint-disable import/no-anonymous-default-export */
import Error404 from "./pages/error/error404";
import Error400 from "./pages/error/error400";
import Error500 from "./pages/error/error500";
import Startup from "./pages/startup";
import Demographic from "./pages/demographic";
import MultiDemographic from "./pages/multi-demographic";
import Indicator from "./pages/indicator";
import Prioritizing from "./pages/prioritizing";
import Result from "./pages/result";
import NoFeedback from "./pages/result/noFeedback";
import StartupCode from "./pages/startup-code";
// import language from "./pages/language";
import AppContainer from "./AppContainer";

export default [
  {
    path: '/code',
    element: <AppContainer />,
    errorElement: <Error500 />,
    children: [
      {
        index: true,
        element: <StartupCode />,
      },
    ]
  },
  {
    path: '/',
    element: <AppContainer />,
    errorElement: <Error500 />,
    children: [
      {
        index: true,
        element: <Startup />,
      },
      {
        path: '/demographic',
        element: <Demographic />,
      },
      {
        path: '/multi-demographic',
        element: <MultiDemographic />,
      },
      {
        path: '/indicator',
        element: <Indicator />,
      },
      {
        path: '/prioritizing',
        element: <Prioritizing />,
      },
      {
        path: '/result',
        element: <Result />,
      },
      {
        path: '/resultNoFeedback',
        element: <NoFeedback />,
      },
      {
        path: '/400',
        element: <Error400 />,
      },
      {
        path: '*',
        element: <Error404 />,
      },
      {
        path: '/500',
        element: <Error500 />,
      },
    ],
  },
  
];