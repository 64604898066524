import { call, put, takeLatest } from "redux-saga/effects";
import {
  getToken,
  getCampaignSurveyData,
  postCampaignSurveyData,
  updateCampaignSurveyData,
  getTokenByCode,
} from "../../http/apis";
import ActionHelper from "../actions/action-helper";
import TOKEN_ACTION_TYPES from "../actions/TOKEN_ACTION_TYPES";
import CAMPAIGN_ACTION_TYPES from "../actions/CAMPAIGN_ACTION_TYPES";
import RESULT_ACTION_TYPES from "../actions/RESULT_ACTION_TYPES";

const debug = require("debug")("state:sagas:token");

export function* tokenSaga({ payload: data }) {
  const { response, error } = yield call(getToken, data);
  let action = TOKEN_ACTION_TYPES.FAILURE;
  let payload = error?.response;
  if (response) {
    action = TOKEN_ACTION_TYPES.SUCCESS;
    payload = response.data;
  } else {
    debug("Error in tokenSaga::getToken:", error);
  }
  yield put(ActionHelper.fire(action, payload));
}

export function* tokenSagaByCode({ payload: data }) {
  const { response, error } = yield call(getTokenByCode, data);
  let action = TOKEN_ACTION_TYPES.FAILURE;
  let payload = error?.response;
  if (response) {
    action = TOKEN_ACTION_TYPES.SUCCESS;
    payload = response.data;
  } else {
    debug("Error in tokenSaga::getToken:", error);
  }
  yield put(ActionHelper.fire(action, payload));
}

export function* campaignSaga({ payload: data }) {
  // let { language } = yield select((state) => state.token);
  // data.lang = language;
  const { response, error } = yield call(getCampaignSurveyData, data);
  let action = CAMPAIGN_ACTION_TYPES.FAILURE;
  let payload = error?.response;
  if (response) {
    action = CAMPAIGN_ACTION_TYPES.SUCCESS;
    payload = response.data;
  } else {
    debug("Error in tokenSaga::getCampaignSurveyData:", error);
  }
  yield put(ActionHelper.fire(action, payload));
}

export function* resultSaga({ payload: data }) {
  const { response, error } = yield call(postCampaignSurveyData, data);
  let action = RESULT_ACTION_TYPES.FAILURE;
  let payload = error?.response;
  if (response) {
    action = RESULT_ACTION_TYPES.SUCCESS;
    payload = response.data;
  } else {
    debug("Error in tokenSaga::postCampaignSurveyData:", error);
  }
  yield put(ActionHelper.fire(action, payload));
}

export function* updateresultSaga(payload) {
  const { response } = yield call(updateCampaignSurveyData, payload.data);
  if (response) {
    payload.callBack(true);
  } else {
    payload.callBack(false);
  }
}

export default function* token() {
  yield takeLatest(TOKEN_ACTION_TYPES.REQUEST, tokenSaga);
  yield takeLatest(TOKEN_ACTION_TYPES.CODE_REQUEST, tokenSagaByCode);
  yield takeLatest(CAMPAIGN_ACTION_TYPES.REQUEST, campaignSaga);
  yield takeLatest(RESULT_ACTION_TYPES.REQUEST, resultSaga);
  yield takeLatest(RESULT_ACTION_TYPES.RESULT_UPDATE_REQUEST, updateresultSaga);
}
