import axios from 'axios';

import { getVars } from '../config/environment';
import { handleRequestError, handleRequestSuccess, handleResponseError, handleResponseSuccess } from './HttpErrorHandler';

const debug = require('debug')('config:axios');

class ApiHelper {
  constructor() {
    const { baseUrl } = getVars();
    this.initialize({
      baseURL: baseUrl,
      timeout: 6000 * 20,
    });
  }

  getAxios() {
    return this.service;
  }

  setAxios(axiosInstance) {
    this.service = axiosInstance;
  }

  initialize(options) {
    this.options = options;
    this.service = axios.create({
      ...this.options,
    });

    this.service.interceptors.request.use(
      handleRequestSuccess,
      handleRequestError
    );
    this.service.interceptors.response.use(
      handleResponseSuccess,
      handleResponseError
    );
    debug('Api Helper Initialization OK!');
  }

  get(path, options) {
    debug(`GET Request to ${path}`);
    return this.service.get(path, options);
  }

  patch(path, payload, options) {
    debug(`PATCH Request to ${path}`);
    return this.service.patch(path, payload, options);
  }

  put(path, payload, options) {
    debug(`PUT Request to ${path}`);
    return this.service.put(path, payload, options);
  }

  post(path, payload, options) {
    debug(`POST Request to ${path}`);
    return this.service.post(path, payload, options);
  }

  delete(path, options) {
    debug(`DELETE Request to ${path}`);
    return this.service.delete(path, options);
  }
}

const apiHelper = new ApiHelper();

export default apiHelper;
