import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";

const AppResultWarningModal = ({ isModalVisible, handleOk, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="commentary-model"
      maskClosable={false}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <h2 className="d-inline-flex align-items-center gap-1">
        <i className="icon-comment-stroke-icon d-none d-lg-block" />
        {t("resultWarningTitle")}
      </h2>
      <p>{t("resultwarningText")}</p>
      <form>
        <div className="d-flex justify-content-between justify-content-sm-start gap-3 mt-3">
          <Link
            to="#"
            aria-label={"Readed"}
            onClick={() => handleOk()}
            className="btn btn-primary"
          >
            <i className="icon-save-icon" />
            {t("Readed")}
          </Link>
        </div>
      </form>
    </Modal>
  );
};

AppResultWarningModal.propTypes = {
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default AppResultWarningModal;
