import React from "react";
import { Provider } from "react-redux";
import AppLayout from "./AppLayout";
import { configureStore } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { Outlet, ScrollRestoration } from 'react-router-dom';


const { store, persistor } = configureStore();

const AppContainer = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <AppLayout>
            <ScrollRestoration />
            <Outlet />
          </AppLayout>
      </PersistGate>
    </Provider>
  );
};

AppContainer.propTypes = {};

export default AppContainer;
