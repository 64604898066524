import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";

const AppResultQuestion = ({ title, active, handleYes, handleNo }) => {
  return (
    <div className="feed">
      <label>{title}</label>
      <Link
        aria-label={"no"}
        to="#"
        className={classNames("no", {
          activeno: active === false ? "activeno" : "",
        })}
        onClick={handleNo}
      >
        <i className="icon-feed-bad-icon"></i>
      </Link>
      <Link
        aria-label={"yes"}
        to="#"
        className={classNames("yes", {
          activeyes: active === true ? "activeyes" : "",
        })}
        onClick={handleYes}
      >
        <i className="icon-feed-ok-icon"></i>
      </Link>
    </div>
  );
};

AppResultQuestion.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  handleYes: PropTypes.func,
  handleNo: PropTypes.func,
};

export default AppResultQuestion;
