import "./AppLayout.scss";
import PropTypes from "prop-types";
import React from "react";

const AppLayout = ({ children }) => {
  return <div className="AppLayout">{children}</div>;
};

AppLayout.props = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
